import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LastUpdated from "../components/LastUpdated/LastUpdated"

import styles from "../components/MatchHistory/MatchHistory.module.css"

class MatchHistories extends React.Component {

  render() {

    return (

      <Layout>
        <SEO
          title={"Match Histories - Messi and Ronaldo Complete Match History Database"}
          description={`Explore Messi and Ronaldo's entire careers - every match, goal, assist, free kick, opponent, competition... everything!`}
          canonicalPath={`/match-histories/`}
        />

        <h1>Match Histories <span className="sr-only">- Messi and Ronaldo Complete Match History Database</span></h1>

        

        <div className="flex flex-col sm:flex-row mt-8 lg:mt-12 mx-auto max-w-xl w-full">

          <Link to={'/match-histories/messi-match-history/'} className={`${styles.matchesLink} w-full sm:w-1/2 px-4 py-8 md:p-8 bg-messi hover:bg-noir-lighten10 transition-300 text-white text-center`}>
            <span className="block uppercase font-bold text-2xl md:text-2xl leading-tight tracking-wide">Lionel Messi</span>
            <span className="block uppercase tracking-widest text-sm leading-snug mt-2">Complete Match History</span>
            <span className="px-10 py-3 border-2 border-white uppercase inline-block mt-6 transition-300">View</span>
          </Link>

          <Link to={'/match-histories/ronaldo-match-history/'} className={`${styles.matchesLink} w-full sm:w-1/2 px-4 py-8 md:p-8 bg-ronaldo hover:bg-noir-lighten10 transition-300 text-white text-center`}>
            <span className="block uppercase font-bold text-2xl md:text-2xl leading-tight tracking-wide">Cristiano Ronaldo</span>
            <span className="block uppercase tracking-widest text-sm leading-snug mt-2">Complete Match History</span>
            <span className="px-10 py-3 border-2 border-white uppercase inline-block mt-6 transition-300">View</span>
          </Link>

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export default MatchHistories
